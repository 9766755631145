define("apollo/pods/components/auction/offers-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionAccount: Ember.inject.service('session-account'),
    intl: Ember.inject.service(),
    auctionSetting: Ember.computed('auction.transport.transportType.id', function () {
      return this.get('sessionAccount').getAuctionSetting(this.get('auction.transport.transportType.id'));
    }),
    showTransitTime: Ember.computed('auctionSetting', function () {
      if (!this.get('auctionSetting')) {
        return false;
      }

      return this.get('auctionSetting.showTransitTime');
    }),
    showDescriptionSection: Ember.computed('auctionSetting', function () {
      if (!this.get('auctionSetting')) {
        return true;
      }

      return this.get('auctionSetting.showDescriptionSection');
    }),
    showPassToCarrierButton: Ember.computed('auctionSetting', 'auction.{transport.id,transport.carrierCompany.id,offers.@each.enabled,offers.length,transport.transaction.id}', function () {
      if (!this.get('auctionSetting')) {
        return true;
      }

      const showPassToCarrierButton = this.get('auctionSetting.showPassToCarrierButton');
      const transportHasNoCarrier = !this.get('auction.transport.carrierCompany.id');
      const passingActionCanBePerformed = this.get('auction.transport.content').actionCanBePerformed('PASS_TRANSPORT');
      const transportHasTransactionAlready = this.get('auction.transport.transaction.id');
      return transportHasNoCarrier && showPassToCarrierButton && passingActionCanBePerformed && !transportHasTransactionAlready;
    }),
    actions: {
      showModal(templateName, model) {
        this.set('action', 'showModal');
        this.sendAction('action', templateName, model);
      }

    }
  });

  _exports.default = _default;
});