define("apollo/pods/auction-setting/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    company: belongsTo('company', {
      async: true
    }),
    transportType: belongsTo('transportType', {
      async: true
    }),
    // Ustawienia ogólne
    translationKey: attr('string'),
    // Formularz informacji o trwającej aukcji
    showFinishAuctionButton: attr('boolean'),
    showPassToCarrierButton: attr('boolean'),
    // Formularz wystawiania aukcji
    defaultCurrency: attr('string'),
    showCompanyGroupsSection: attr('boolean'),
    selectionBasedOnlyOnGroups: attr('boolean'),
    showBlockedCompaniesSection: attr('boolean'),
    requireMaxPrice: attr('boolean'),
    requireMinPrice: attr('boolean'),
    showPriceRangeFields: attr('boolean'),
    selectAllCarriersByDefault: attr('boolean'),
    // Formularz składania ofert
    canAcceptWithMinPrice: attr('boolean'),
    showDescriptionSection: attr('boolean'),
    requireDescriptionSection: attr('boolean'),
    requireMinOfferReductionSizeValidation: attr('boolean'),
    showRevokeOfferButton: attr('boolean'),
    showQuantityFieldInOfferForm: attr('boolean'),
    requireReasonIfNotBestOfferIsChosen: attr('boolean'),
    exportAuctionInfoToExcel: attr('boolean'),
    showStepDataInOfferForm: attr('boolean'),
    minOfferReductionSize: attr('number'),
    defaultAuctionFinishDelayInterval: attr('string'),
    auctionProlongationOffsetInterval: attr('string'),
    reminderTimeAboutAuction: attr('string'),
    addFileToTransportAfterOfferAcceptationName: attr('string'),
    addFileToTransportAfterOfferAcceptation: attr('boolean'),
    newAuctionEmailFileName: attr('string'),
    auctionEndedWithoutOffersEmailFileName: attr('string'),
    showTransitTime: attr('boolean'),
    requireTransitTime: attr('boolean'),
    shouldPassToCarrierAfterAuction: attr('boolean'),
    auctionEmailSubjectPl: attr('string'),
    auctionEmailSubjectEn: attr('string'),
    showUnitAmountType: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    requireUnitAmountType: attr('boolean', {
      defaultValue() {
        return false;
      }

    })
  });

  _exports.default = _default;
});