define("apollo/pods/components/auction/auction-details-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IDxKgfvs",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"btn btn-sm btn-default vertical-margin-small\"],[11,\"data-button-name\",\"auction-history\"],[12,\"style\",[23,[\"transport\",\"backgroundColorStyle\"]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"showBidCounter\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"auction.bidsCount\"],null],false],[0,\" \"],[1,[21,\"currentBidCount\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[27,\"t\",[\"auction.details\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[3,\"action\",[[22,0,[]],\"showAuctionDetailsForm\"]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/auction/auction-details-button/template.hbs"
    }
  });

  _exports.default = _default;
});