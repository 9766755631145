define("apollo/pods/transaction/model", ["exports", "ember-data", "ember-data/model", "ember-data-copyable", "apollo/mixins/model-validator"], function (_exports, _emberData, _model, _emberDataCopyable, _modelValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    attr
  } = _emberData.default;

  var _default = _model.default.extend(_modelValidator.default, _emberDataCopyable.default, {
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    transport: belongsTo('transport', {
      async: true
    }),
    carrier: belongsTo('company', {
      async: true
    }),
    currency: belongsTo('currency'),
    routeOffer: belongsTo('routeOffer', {
      async: true
    }),
    autoRouteOfferPass: attr('boolean'),
    autoRouteOfferPassNotAccepted: attr('boolean'),
    autoRouteOfferPassError: attr('boolean'),
    invoiceNumber: attr('string'),
    invoicePrice: attr('number'),
    invoiceCurrency: belongsTo('currency'),
    isInvoiceForm: attr('boolean'),
    choosingReason: belongsTo('specialFieldOption'),
    expectedConfirmationDate: attr('isodate'),
    price: attr('number'),
    carrierMustAcceptTransport: attr('boolean', {
      defaultValue() {
        return true;
      }

    }),
    carrierAutoAcceptsTransport: attr('boolean', {
      defaultValue() {
        return true;
      }

    }),
    isRejected: attr('boolean', {
      defaultValue() {
        return false;
      }

    }),
    // typ serwisu kurierskiego
    typeOfService: attr('string'),
    unitAmountType: belongsTo('transactionUnitAmountType', {
      async: true
    }),
    invoiceState: Ember.computed(function () {
      const withInvoice = this.get('intl').t('transaction.withInvoice').toString();
      const withoutInvoice = this.get('intl').t('transaction.withoutInvoice').toString();

      if (this.get('invoiceNumber')) {
        return {
          name: withInvoice,
          key: 'WITH_INVOICE'
        };
      } else {
        return {
          name: withoutInvoice.toString(),
          key: 'WITHOUT_INVOICE'
        };
      }
    }).readOnly(),
    validations: {
      carrier: {
        custom: [{
          validation: function (key, value) {
            return value.get('id') || value.content;
          },
          message: 'blank'
        }]
      },
      price: {
        custom: [{
          validation: function (key, value, model) {
            return !(model.get('showPriceField') && !value);
          },
          message: 'blank'
        }]
      },
      invoiceNumber: {
        custom: [{
          validation: function (key, value, model) {
            return !model.get('isInvoiceForm') || value != null && value.length > 0;
          },
          message: 'blank'
        }]
      },
      invoicePrice: {
        custom: [{
          validation: function (key, value, model) {
            return !model.get('isInvoiceForm') || value != null;
          },
          message: 'blank'
        }, {
          validation: function (key, value, model) {
            if (!model.get('isInvoiceForm') || !value) {
              return true;
            }

            return /[0-9]+(\.|\,[0-9]{1,2})?$/.test(value);
          },
          message: 'wrongNumberFormat'
        }]
      },
      expectedConfirmationDate: {
        custom: [{
          validation: function (key, value, model) {
            // walidacja nie obowiązuje w formularzu faktury
            if (model.get('isInvoiceForm')) {
              return true;
            }

            return value > new Date();
          },
          message: 'isInPast'
        }]
      },
      choosingReason: {
        custom: [{
          validation: function (key, value, model) {
            if (model.get('requireReasonFieldIfPriceIsZero')) {
              const anyDeliveryHasZeroCost = model.get('transport.deliveries').any(d => {
                return d.get('freightCost') && Number(d.get('freightCost')) === 0;
              });

              if (Number(model.get('price')) === 0 || anyDeliveryHasZeroCost) {
                return value && value.get('id');
              }
            }

            if (model.get('transport.activeAuction')) {
              if (!model.get('requireAuctionChoosingReason')) {
                return true;
              } else {
                return value && value.get('id');
              }
            } else {
              if (model.get('requireTransactionChoosingReason') && model.get('mandatoryTransactionChoosingReason')) {
                return value && value.get('id');
              } else {
                return true;
              }
            }
          },
          message: 'blank'
        }]
      },
      unitAmountType: {
        custom: [{
          validation: function (key, value, model) {
            return !(model.get('requireUnitAmountType') && !value.get('id'));
          },
          message: 'blank'
        }]
      }
    },
    requireReasonFieldIfPriceIsZero: Ember.computed('transport.transportType.id', function () {
      const transactionSetting = this.get('sessionAccount').getTransactionSetting(this.get('transport.transportType'));

      if (!transactionSetting) {
        return false;
      }

      return transactionSetting.get('showAndRequireReasonFieldIfPriceIsZero');
    }),
    priceLabelTranslationKey: Ember.computed('transport.transportType.id', function () {
      const transactionSetting = this.get('sessionAccount').getTransactionSetting(this.get('transport.transportType'));

      if (!transactionSetting) {
        return 'FREIGHT';
      }

      return transactionSetting.get('priceLabel');
    }),
    requireAuctionChoosingReason: Ember.computed('notTheBestOffer', 'transport.transportType.id', function () {
      const auctionSetting = this.get('sessionAccount').getAuctionSetting(this.get('transport.transportType.id'));

      if (!auctionSetting) {
        return false;
      }

      return auctionSetting.get('requireReasonIfNotBestOfferIsChosen') && this.get('notTheBestOffer');
    }),
    requireTransactionChoosingReason: Ember.computed('transport.transportType.id', function () {
      const transactionSetting = this.get('sessionAccount').getTransactionSetting(this.get('transport.transportType'));

      if (!transactionSetting) {
        return false;
      }

      return transactionSetting.get('showReasonField');
    }),
    mandatoryTransactionChoosingReason: Ember.computed('transport.transportType.id', function () {
      const transactionSetting = this.get('sessionAccount').getTransactionSetting(this.get('transport.transportType'));

      if (!transactionSetting) {
        return false;
      }

      return transactionSetting.get('requiresReasonField');
    }),
    showExpectedConfirmationDate: Ember.computed('transport.transportType.id', function () {
      const transactionSetting = this.get('sessionAccount').getTransactionSetting(this.get('transport.transportType'));

      if (!transactionSetting) {
        return false;
      }

      return transactionSetting.get('showExpectedConfirmationDate');
    }),
    showPriceField: Ember.computed('transport.transportType.id', function () {
      const transactionSetting = this.get('sessionAccount').getTransactionSetting(this.get('transport.transportType'));

      if (!transactionSetting) {
        return false;
      }

      return transactionSetting.get('showPriceField');
    }),
    showPriceForDeliveriesField: Ember.computed('transport.transportType.id', function () {
      const transactionSetting = this.get('sessionAccount').getTransactionSetting(this.get('transport.transportType'));

      if (!transactionSetting) {
        return false;
      }

      return transactionSetting.get('showPriceFieldForDeliveries');
    }),
    showUnitAmountType: Ember.computed('transport.transportType.id', function () {
      const transactionSetting = this.get('sessionAccount').getTransactionSetting(this.get('transport.transportType'));

      if (!transactionSetting) {
        return false;
      }

      return transactionSetting.get('showUnitAmountType');
    }),
    requireUnitAmountType: Ember.computed('transport.transportType.id', function () {
      const transactionSetting = this.get('sessionAccount').getTransactionSetting(this.get('transport.transportType'));

      if (!transactionSetting) {
        return false;
      }

      return transactionSetting.get('requireUnitAmountType');
    })
  });

  _exports.default = _default;
});