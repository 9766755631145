define("apollo/pods/offers/modals/offer-form/controller", ["exports", "jquery", "apollo/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    sessionAccount: Ember.inject.service('session-account'),
    offer: Ember.computed('model.offer', function () {
      return this.get('model.offer');
    }),
    auction: Ember.computed('offer.auction', function () {
      return this.get('offer.auction');
    }),
    transport: Ember.computed('auction.transport', function () {
      return this.get('auction.transport');
    }),
    title: Ember.computed('transport.identifier', 'transport.formattedCities', function () {
      return `${this.get('transport.identifier')}: ${this.get('transport.formattedCities')}`;
    }),
    customFields: Ember.computed('model.customFields', function () {
      return this.get('model.customFields');
    }),
    buyNowConfirmationMsg: Ember.computed('transport.identifier', 'offer.price', 'auction.currency.symbol', function () {
      return this.get('intl').t('auction.buyNowConfirmation', {
        transportIdentifier: this.get('transport.identifier'),
        minPrice: this.get('offer.price'),
        currencySymbol: this.get('auction.currency.symbol')
      });
    }),
    auctionSetting: Ember.computed('transport.transportType.id', function () {
      return this.get('sessionAccount').getAuctionSetting(this.get('transport.transportType.id'));
    }),
    showTransitTime: Ember.computed('auctionSetting', function () {
      if (!this.get('auctionSetting')) {
        return false;
      }

      return this.get('auctionSetting.showTransitTime');
    }),
    canAcceptWithMinPrice: Ember.computed('auction.minPrice', 'auctionSetting', function () {
      const auctionSetting = this.get('auctionSetting');

      if (!auctionSetting) {
        return true;
      }

      return this.get('auction.minPrice') && auctionSetting.get('canAcceptWithMinPrice');
    }),
    showQuantityFieldInOfferForm: Ember.computed('transport', 'transport.activeAuction', 'auctionSetting', function () {
      const auctionSetting = this.get('auctionSetting');

      if (!auctionSetting) {
        return false;
      }

      return this.get('transport.activeAuction') && auctionSetting.get('showQuantityFieldInOfferForm');
    }),
    showStepDataInOfferForm: Ember.computed('auctionSetting', function () {
      const auctionSetting = this.get('auctionSetting');

      if (!auctionSetting) {
        return true;
      }

      return auctionSetting.get('showStepDataInOfferForm');
    }),
    auctionRejectConfirmationText: Ember.computed('auctionSetting', function () {
      const auctionSetting = this.get('auctionSetting');

      if (!auctionSetting) {
        return this.get('intl').t('auction.AUCTION.reject.alert');
      }

      const key = auctionSetting.get('translationKey');
      return this.get('intl').t(`auction.${key}.reject.alert`);
    }),
    showRevokeOfferButton: Ember.computed('auctionSetting', 'auction', 'auction.currentOfferForLoggedCompany', function () {
      const auctionSetting = this.get('auctionSetting');

      if (!auctionSetting) {
        return false;
      }

      const currentPrice = this.get('auction.currentOfferForLoggedCompany.formattedPrice');
      return currentPrice && auctionSetting.get('showRevokeOfferButton');
    }),
    showDescriptionSection: Ember.computed('auctionSetting', function () {
      const auctionSetting = this.get('auctionSetting');

      if (!auctionSetting) {
        return true;
      }

      return auctionSetting.get('showDescriptionSection');
    }),
    dateToPerformAction: '',
    actionPlanned: false,
    executeAt: function (timeString, func) {
      const time = moment(timeString).toDate();
      const currentTime = new Date().getTime();
      setTimeout(func, time - currentTime);
      return true;
    },
    actions: {
      performSaveAt(date) {
        this.executeAt(date, () => {
          $("[data-button-name='save-offer']").click();
          this.set('actionPlanned', false);
        });
        this.set('actionPlanned', true);
      },

      buyNow() {
        const auction = this.get('auction');
        const currentPrice = this.get('offer.price');
        this.get('offer').setProperties({
          oldPrice: currentPrice,
          price: auction.get('minPrice'),
          isAcceptedWithMinPrice: true
        });
        this.send('save');
      },

      save() {
        console.debug(`Saving offer for auction ${this.get('auction.id')} of transport ${this.get('transport.id')}..`);

        if (this.get('canAcceptWithMinPrice') && this.get('offer.price') <= this.get('auction.minPrice') && !confirm(this.get('buyNowConfirmationMsg'))) {
          this.get('offer').setProperties({
            price: this.get('offer.oldPrice'),
            isAcceptedWithMinPrice: false
          });
          return;
        }

        const self = this;
        this.set('savingInProgress', true);

        if (!this.get('offer').validate()) {
          console.debug('Offer did not pass validation.');
          self.set('savingInProgress', false);
          return;
        }

        this.get('offer').save().then(savedOffer => {
          if (savedOffer.get('valid')) {
            console.debug(`Offer successfully saved with id ${savedOffer.get('id')}!`);
            self.set('transport.transportType.forcedPollRequestTime', new Date().getTime());
            self.setProperties({
              savingInProgress: false,
              errors: []
            });
            self.send('hideModal');
          } else {
            const errorMessage = self.get('intl').t('offer.errors.price.higherThanMaximum'); // Po zapisaniu oferty z ceną wyższą niż cena maks. aukcji, musimy przygotować nowy model
            // - by można było poprawić ofertę.

            const offerModel = this.get('store').createRecord('offer', {
              auction: savedOffer.get('auction'),
              price: savedOffer.get('price')
            });
            const model = {
              offer: offerModel,
              customFields: self.get('customFields')
            };
            self.setProperties({
              model,
              savingInProgress: false,
              errors: [errorMessage]
            });
          }
        }).catch(response => {
          const status = response.errors ? response.errors[0].status : undefined;
          let errors = response.errors;

          if (status === '401' || status === '403') {
            const errorMsg = self.get('intl').t('offer.errors.auction.hasEnded');
            alert(errorMsg);
            errors = [];
            self.set('transport.transportType.forcedPollRequestTime', new Date().getTime());
            self.send('hideModal');
          }

          self.setProperties({
            savingInProgress: false,
            errors
          });
        });
      },

      reject() {
        const self = this;
        const token = this.get('sessionAccount').get('token');
        const auctionSetting = this.get('auctionSetting');
        let translationKey = 'AUCTION';

        if (auctionSetting) {
          translationKey = auctionSetting.get('translationKey');
        } // TODO :: apollo-api-service


        _jquery.default.ajax({
          url: _environment.default.serverURL + '/auctions/' + this.get('auction.id') + '/reject',
          type: 'PUT',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(respond => {
          if (!respond.error) {
            self.send('hideModal');
            alert(self.get('intl').t(`auction.${translationKey}.reject.success`));
          } else {
            alert(self.get('intl').t(`auction.${translationKey}.reject.fail.` + respond.error));
          }
        });
      },

      revokeOffer() {
        const self = this;
        const token = this.get('sessionAccount').get('token'); // TODO :: apollo-api-service

        _jquery.default.ajax({
          url: _environment.default.serverURL + '/auctions/' + this.get('auction.id') + '/revokeOffer',
          type: 'PUT',
          beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + token);
          }
        }).then(respond => {
          if (!respond.error) {
            self.send('hideModal');
            alert(self.get('intl').t('offer.revoke.success'));
          } else {
            alert(self.get('intl').t('offer.revoke.fail.' + respond.error));
          }

          self.get('transport.activeAuction').reload();
        });
      },

      cancel() {
        console.debug('Zamykamy okienko ofertacji...');

        if (!this.get('offer.id')) {
          this.get('auction.offers').removeObject(this.get('offer'));
          this.get('offer').unloadRecord();
        }

        this.set('errors', []);
      }

    }
  });

  _exports.default = _default;
});